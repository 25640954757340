import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {    
     
    fetchTools(ctx, queryParams) { 
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + '/tools', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTool(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/getToolById?tool_id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }, 
    addTool(ctx, toolData) {
      var bodyFormData = new FormData();
      bodyFormData.append('tool_name', toolData.tool_name);
      bodyFormData.append('tool_remark', toolData.tool_remark);
      bodyFormData.append('tool_status', toolData.tool_status );
      bodyFormData.append('tool_created_by', toolData.tool_created_by);  
      
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_HOST + '/tool',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTool(ctx, toolData) {
      var bodyFormData = new FormData();
      bodyFormData.append('tool_id', toolData.tool_id);
      bodyFormData.append('tool_name', toolData.tool_name);
      bodyFormData.append('tool_remark', toolData.tool_remark);
      bodyFormData.append('tool_status', toolData.tool_status );
      bodyFormData.append('tool_updated_by', toolData.tool_updated_by);  
      
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_HOST + '/tool',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
